/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-25 00:08:26
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:03:57
 */
import { mapGetters } from 'vuex'
import EnumMap from '@/plugins/enumMap'
export default {
  name: 'DatapackageDetail',
  data () {
    return {
      form: {
        acitve_list: [],
        compose_type: '',
        data_type_list: [],
        id: '',
        name: '',
        remove_list: [],
        source_type: ''
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  methods: {
    async init (id) {
      const data = await this.$wPost('/operate/datapackage/diy/get.do', { id })
      this.form = Object.assign({}, this.form, data)
    },
    edit () {
      this.$router.push({ path: '/operate/datapackage' })
    },
    activityStatus (val) {
      const name = new EnumMap('package_diy_source').mapping(val)
      return name
    },
    dataTypeList (val) {
      const name = new EnumMap('package_data_type').mapping(val)
      return name
    }
  },
  computed: {
    ...mapGetters([
      'enum_map'
    ])
  },
  mounted () {
    const id = this.$route.params.id - 0
    this.init(id)
  }
}
