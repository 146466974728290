/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-25 00:08:31
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-25 00:08:31
 */
import DatapackageDetail from './DatapackageDetail.vue'
export default DatapackageDetail
