/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-21 11:49:48
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-24 19:58:53
 */
import Store from '../../store'
import { cloneDeep } from 'lodash'

class EnumMap {
  constructor (propName) {
    this.propName = cloneDeep(Store.state.enum_map[propName])
  }

  mapping (val) {
    let backVal = ''
    for (let i = 0; i < this.propName.length; i++) {
      if (val === this.propName[i].id) {
        backVal = this.propName[i].name
        break
      }
    }
    return backVal
  }
}

export default EnumMap
